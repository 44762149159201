// Fonts
$font-standard: 'K2D', sans-serif;;
$font-title: 'Amatic SC', cursive;

$font-size-standard: 1.6em; // 16px
$font-size-small: 1.4em; // 14px

$font-line-height-standard: 1.3em;
$font-line-height-small: 1.1em;
$font-line-height-big: 1.5em;

//Layout
$size-header-height: 95px;
$size-header-height-scrolled: 50px;
$size-header-width: 100%;
$size-header-margin: 50px;
$size-header-margin-small: 30px;
$size-header-width-scrolled: 1000px;

$size-content-width: 1000px;
$size-content-width-info-page: 800px;
$size-content-margin: 50px;
$size-content-margin-small: 30px;
$size-border-radius: 5px;

$size-grid-cutter: 2em;
$size-grid-cutter-small: 1.2em;

$spacer-height-small: 2em;
$spacer-height-medium: 3em;
$spacer-height-big: 6em;

// Colors
$color-white: #fff;
$color-black: #000;

$color-brown1: #996700;
$color-brown2: #63311b;
$color-brown3: #422a1d;
$color-red1: #993500;
$color-orange1: #ff9d00;
$color-yellow1: #ffce00;
$color-green1: #79aa00;
$color-green2: #4b6307;

$color-nav-inactiv: $color-brown2;
$color-nav-hover: $color-brown2;
$color-nav-active: $color-brown2;

$color-error: #eb5757;
$color-warning: #e2b93b;
$color-success: #27ae60;
$color-info: #2F80ED;

// Elements

// BASE

html,
body {
  font-family: $font-standard;
  font-size: 10px;
  margin: 0;
  padding: 0;
  height: 100%;
  color: $color-brown2;
}

* {
  box-sizing: border-box;
  margin: 0;
}

.clear {
  clear: both;
}

img {
  width: 100%;
  height: auto;
}

.lazy-container {
  width: 100%;
  position: relative;
//   float: left;
  height: 0;
}

.lazy-870x500 {
  padding-bottom: 57.471%;
}

.lazy-2000x833 {
  padding-bottom: 41.65%;
}

.lazy-img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}

a {
  text-decoration: none;
  color: $color-brown2;
    &:active,
    &:visited,
    &:hover {
      color: $color-brown2;
  }
}

section.content {
  padding: 6em 0;
}

.content-width {
  position: relative;
  max-width: $size-content-width + (2 * $size-content-margin);
  height: 100%;
  margin: 0 auto;
  padding-left: $size-content-margin;
  padding-right: $size-content-margin;
  transition: all 0.5s ease;
}


// GRID

.grid {
  overflow: hidden;
  margin-left: -$size-grid-cutter;
  margin-right: -$size-grid-cutter;
}
.col {
  overflow: hidden;
  padding-left: $size-grid-cutter;
  padding-right: $size-grid-cutter;
  float: left;
}
.col-100 {
  width: 100%;
}
.col-50-2 {
  width: 50%;
}
.col-33-3 {
  width: 33.333%;
}
.col-33-2 {
  width: 33.333%;
}
.col-66-2 {
  width: 66.333%;
}



//SPACER
.spacer {
  position: relative;
  text-align: center;
}
.spacer-height-small {
  height: $spacer-height-small;
}
.spacer-height-medium {
  height: $spacer-height-medium;
}
.spacer-height-big {
  height: $spacer-height-big;
}
.spacer-line {
  position: absolute;
  width: 50%;
  top: 50%; left: 50%;
  transform: translate(-50%,-50%);
  height: 1px;
  background-color: #545454;
}


// HEADER

// Just to read out in the app.js
#headerheightscrolled {
  display: none;
  height: $size-header-height-scrolled;
}

#header {
  z-index: 20;
  position: fixed;
  top: 0;
  width: 100%;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 10%, rgba(20,0,0,0.5) 50%, rgba(0,0,0,0.9) 100%);
}

.header-content {
  height: $size-header-height;
  padding-left: $size-header-margin;
  padding-right: $size-header-margin;
}

.header-content-width {
  position: relative;
  height: 100%;
  max-width: $size-header-width;
  transition: all 0.5s ease;
}

#logo {
  display: inline-block;
  position: absolute;
  z-index: 100;
  right: 0;
  top: -20px;
  width: 200px;
  height: 116px;
}

.logo-positiv,
.logo-negativ {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  opacity: 1;
  transition: width 0.5s ease,
              height 0.5s ease,
              opacity 0.2s ease;
  background-image: url(../img/logo_brown.svg);
  background-size: contain;
  background-repeat: no-repeat;
}
.logo-negativ {
  background-image: url(../img/logo_white.svg);
  opacity: 1;
}

#header {
  nav {
    position: absolute;
    bottom: 35px;
    font-weight: bold;
    ul {
      list-style: none;
      padding-left: 0;
      li {
        float: left;
        margin-left: 10px;
        margin-right: 10px;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        a {
          color: $color-white;
          font-size: 20px;
          text-decoration: none;
          text-transform: uppercase;
          display: inline-block;
          padding-bottom: 5px;
          border-bottom: 2px solid rgba(255, 255, 255, 0);
          transition: border-bottom 1s;
          &:hover {
            border-bottom: 2px solid rgba(255, 255, 255, 1);
          }
        }
      }
    }
  }
}

.pagescrolled {
  #header {
    background: $color-white;
    -webkit-box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.2);
  }
  .header-content {
    height: $size-header-height-scrolled;
    padding-left: 0;
    padding-right: 0;
    margin-left: $size-content-margin;
    margin-right: $size-content-margin;
  }
  .header-content-width {
    max-width: $size-header-width-scrolled;
    margin: 0 auto;
    padding-left: -$size-content-margin;
    padding-right: -$size-content-margin;
  }
  .logo-negativ {
    opacity: 0;
  }
  #header nav {
    margin-left: 5px;
    margin-right: 5px;
    bottom: 5px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    a {
      color: $color-brown2;
      font-size: 16px;
      &:hover {
        border-bottom: 2px solid rgba(99, 48, 27, 1);
      }
      &.act {
        color: $color-nav-active;
        border-bottom: 2px solid rgba(99, 48, 27, 1);
      }
    }
  }
}


/* Mobilenavigation */
#hamburger {
  z-index: 100;
  display: none;
  position: absolute;
  left: 0;
  top: $size-header-height - 50px;
  width: 33px;
  height: 33px;
  overflow: hidden;
  transition: all 0.3s;
  cursor: pointer;
  span {
    background: $color-white;
    display: block;
    height: 3px;
    margin: 7px 0;
    position: relative;
    transform: rotate(0);
    top: 0;
    left: 0;
    opacity: 1;
    transition: none 0.5s ease;
    transition-property: transform, top, left, opacity;
  }
  &.nav-open span.top-bar {
    transform: rotate(45deg);
    top:10px;
  }
  &.nav-open span.middle-bar {
    opacity: 0;
    left: -40px;
  }
  &.nav-open span.bottom-bar {
    transform: rotate(-45deg);
    top: -10px;
  }
  &.nav-open {
    display: inherit;
  }
}

.pagescrolled #hamburger {
  top: 8px !important;
  span {
    background: $color-brown2;
  }
}



.small-screen {
  #header nav {
    display: none;
    position: absolute;
    top: 0;
    left: -15px;
    right: -15px;
    width: inherit;
    height: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-top: 90px;
    padding-bottom: 20px;
    background-color: $color-brown2;
    ul {
      background-color: $color-brown2;
      padding-bottom: 20px;
      li {
        float: none;
        margin: 0 !important;
        a {
          width: 100%;
          background-color: $color-brown2;
          padding: 10px 15px;
          color: $color-white !important;
          font-size: 18px;
          &:hover {
            border-bottom: 2px solid rgba(99, 48, 27, 1);
            color: $color-yellow1 !important;
          }
          &.act {
            border-bottom: 2px solid rgba(99, 48, 27, 1);
            color: $color-yellow1 !important;
          }
        }
      }  
    }
  }
  #hamburger {
    display: inherit;
    top: 20px;
    &.nav-open span {
      background: $color-white;
    }
  }
}



//SLIDER

.fullscreen {
  height: 100vh;
  padding: 0;
}

#home-slider {
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
}

// 400px
#home-slider {
  .slide-1 { background: url(../../images/slider/lebenskrise-coaching-pferd-400.webp) right center / cover; }
  .slide-2 { background: url(../../images/slider/konzentrationstraining-kinder-pferd-400.webp) right center / cover; }
  .slide-3 { background: url(../../images/slider/leadership-training-pferd-400.webp) right center / cover; }
  .slide-4 { background: url(../../images/slider/hilfe-kinder-jugendliche-autismus-pferd-400.webp) center center / cover; }
  .slide-5 { background: url(../../images/slider/pferde-coaching-unterstuetzung-400.webp) right top / cover; }
}
.no-webp #home-slider {
  .slide-1 { background: url(../../images/slider/lebenskrise-coaching-pferd-400.jpg) right center / cover; }
  .slide-2 { background: url(../../images/slider/konzentrationstraining-kinder-pferd-400.jpg) right center / cover; }
  .slide-3 { background: url(../../images/slider/leadership-training-pferd-400.jpg) right center / cover; }
  .slide-4 { background: url(../../images/slider/hilfe-kinder-jugendliche-autismus-pferd-400.jpg) center center / cover; }
  .slide-5 { background: url(../../images/slider/pferde-coaching-unterstuetzung-400.jpg) right top / cover; }
}

// 400px retina
@media screen and (max-width: 400px) and (-webkit-min-device-pixel-ratio: 2), screen and (max-width: 400px) and (min-resolution: 192dpi) {
  #home-slider {
    .slide-1 { background: url(../../images/slider/lebenskrise-coaching-pferd-400@2x.webp) right center / cover; }
    .slide-2 { background: url(../../images/slider/konzentrationstraining-kinder-pferd-400@2x.webp) right center / cover; }
    .slide-3 { background: url(../../images/slider/leadership-training-pferd-400@2x.webp) right center / cover; }
    .slide-4 { background: url(../../images/slider/hilfe-kinder-jugendliche-autismus-pferd-400@2x.webp) center center / cover; }
    .slide-5 { background: url(../../images/slider/pferde-coaching-unterstuetzung-400@2x.webp) right top / cover; }
  }
  .no-webp #home-slider {
    .slide-1 { background: url(../../images/slider/lebenskrise-coaching-pferd-400@2x.jpg) right center / cover; }
    .slide-2 { background: url(../../images/slider/konzentrationstraining-kinder-pferd-400@2x.jpg) right center / cover; }
    .slide-3 { background: url(../../images/slider/leadership-training-pferd-400@2x.jpg) right center / cover; }
    .slide-4 { background: url(../../images/slider/hilfe-kinder-jugendliche-autismus-pferd-400@2x.jpg) center center / cover; }
    .slide-5 { background: url(../../images/slider/pferde-coaching-unterstuetzung-400@2x.jpg) right top / cover; }
  }
}

// 800px
@media screen and (min-width: 401px) and (max-width: 800px) {
  #home-slider {
    .slide-1 { background: url(../../images/slider/lebenskrise-coaching-pferd-800.webp) right center / cover; }
    .slide-2 { background: url(../../images/slider/konzentrationstraining-kinder-pferd-800.webp) right center / cover; }
    .slide-3 { background: url(../../images/slider/leadership-training-pferd-800.webp) right center / cover; }
    .slide-4 { background: url(../../images/slider/hilfe-kinder-jugendliche-autismus-pferd-800.webp) center center / cover; }
    .slide-5 { background: url(../../images/slider/pferde-coaching-unterstuetzung-800.webp) right top / cover; }
  }
  .no-webp #home-slider {
    .slide-1 { background: url(../../images/slider/lebenskrise-coaching-pferd-800.jpg) right center / cover; }
    .slide-2 { background: url(../../images/slider/konzentrationstraining-kinder-pferd-800.jpg) right center / cover; }
    .slide-3 { background: url(../../images/slider/leadership-training-pferd-800.jpg) right center / cover; }
    .slide-4 { background: url(../../images/slider/hilfe-kinder-jugendliche-autismus-pferd-800.jpg) center center / cover; }
    .slide-5 { background: url(../../images/slider/pferde-coaching-unterstuetzung-800.jpg) right top / cover; }
  }
}

// 800px retina
@media screen and (min-width: 401px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 401px) and (min-resolution: 192dpi) {
  #home-slider {
    .slide-1 { background: url(../../images/slider/lebenskrise-coaching-pferd-800@2x.webp) right center / cover; }
    .slide-2 { background: url(../../images/slider/konzentrationstraining-kinder-pferd-800@2x.webp) right center / cover; }
    .slide-3 { background: url(../../images/slider/leadership-training-pferd-800@2x.webp) right center / cover; }
    .slide-4 { background: url(../../images/slider/hilfe-kinder-jugendliche-autismus-pferd-800@2x.webp) center center / cover; }
    .slide-5 { background: url(../../images/slider/pferde-coaching-unterstuetzung-800@2x.webp) right top / cover; }
  }
  .no-webp #home-slider {
    .slide-1 { background: url(../../images/slider/lebenskrise-coaching-pferd-800@2x.jpg) right center / cover; }
    .slide-2 { background: url(../../images/slider/konzentrationstraining-kinder-pferd-800@2x.jpg) right center / cover; }
    .slide-3 { background: url(../../images/slider/leadership-training-pferd-800@2x.jpg) right center / cover; }
    .slide-4 { background: url(../../images/slider/hilfe-kinder-jugendliche-autismus-pferd-800@2x.jpg) center center / cover; }
    .slide-5 { background: url(../../images/slider/pferde-coaching-unterstuetzung-800@2x.jpg) right top / cover; }
  }
}

// 1600px
@media screen and (min-width: 801px) {
  #home-slider {
    .slide-1 { background: url(../../images/slider/lebenskrise-coaching-pferd-1600.webp) right center / cover; }
    .slide-2 { background: url(../../images/slider/konzentrationstraining-kinder-pferd-1600.webp) right center / cover; }
    .slide-3 { background: url(../../images/slider/leadership-training-pferd-1600.webp) right center / cover; }
    .slide-4 { background: url(../../images/slider/hilfe-kinder-jugendliche-autismus-pferd-1600.webp) center center / cover; }
    .slide-5 { background: url(../../images/slider/pferde-coaching-unterstuetzung-1600.webp) right top / cover; }
  }
  .no-webp #home-slider {
    .slide-1 { background: url(../../images/slider/lebenskrise-coaching-pferd-1600.jpg) right center / cover; }
    .slide-2 { background: url(../../images/slider/konzentrationstraining-kinder-pferd-1600.jpg) right center / cover; }
    .slide-3 { background: url(../../images/slider/leadership-training-pferd-1600.jpg) right center / cover; }
    .slide-4 { background: url(../../images/slider/hilfe-kinder-jugendliche-autismus-pferd-1600.jpg) center center / cover; }
    .slide-5 { background: url(../../images/slider/pferde-coaching-unterstuetzung-1600.jpg) right top / cover; }
  }
}


.webp #testimonial-slider,
.webp #footer .contact-block {
  background: url(../../images/full/pferdemut-kundenstimmen-hintergrund-2000.webp);
  background-position: center center;
  background-size: cover;
}

.no-webp #testimonial-slider,
.no-webp #footer .contact-block {
  background: url(../../images/full/pferdemut-kundenstimmen-hintergrund-2000.jpg);
  background-position: center center;
  background-size: cover;
}




// Slide-Content Animation
.swiper-slide {
  .teaser {
    opacity: 0;
    transition: all 0.6s ease;
    transition-delay: .75s;
    color: $color-white;
  }
  .testimonial {
    blockquote,
    p {
      transform: translateY(-30px);
      opacity: 0;
      transition: all 0.4s ease;
      transition-delay: 0.5s;
    }
  }
}
.swiper-slide-duplicate-active,
.swiper-slide-active {
  .teaser {
    opacity: 1;
  }
  .testimonial {
    blockquote,
    p {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.swiper-button-prev,
.swiper-button-next {
  width: 1.6em;
  height: 5em;
  background-image: url(../img/slider_arrow_white.svg);
  background-repeat: no-repeat;
  color: rgba(255, 255, 255, 0);
}
.swiper-button-prev {
  transform: rotate(180deg);
  left: 25px;
}
.swiper-button-next {
  right: 25px;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 1.5em;
}
.swiper-pagination-bullet,
.swiper-pagination-bullet-active {
  border: 2px solid $color-white;
  background: none;
  width: 15px;
  height: 15px;
  opacity: 1;
}
.swiper-pagination-bullet:hover {
  background: $color-white;
}
.swiper-pagination-bullet-active {
  background: $color-white;
}


.teaser {
  position: absolute;
  bottom: 15%;
  margin-bottom: 50px;
  left: $size-header-margin *2;
  max-width: 500px;
  padding: 2em;
  margin-right: $size-header-margin;
  color: $color-white;
  &:hover {
    color: $color-white;
  }
  .background {
    opacity: 0.75;
  }
  .text {
    position: relative;
  }
  .title {
    font-family: $font-title;
    font-size: 6em;
    line-height: 0.9em;
    margin: 0 0 0.2em 0;
  }
  .description {
    font-size: 2em;
    font-weight: 700;
  }
  .cta {
    font-size: 1.6em;
    font-weight: 700;
    line-height: 1.4;
    text-align: right;
    margin: .6em 0 .2em 0;
    padding-left: 1.8em;
    &::before {
      content: "";
      position: absolute;
      margin-left: -1.8em;
      background: url(../img/icon_arrow_white_passiv.svg);
      background-repeat: no-repeat;
      background-size: contain;
      color: $color-white;
      width: 1.4em;
      height: 1.4em;
    }
    &:hover {
      color: $color-white;
      &:before {
        background-image: url(../img/icon_arrow_white_activ.svg);
      }
    }
  }
}


#teaser-content-arrow {
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 10;
  bottom: 20px;
  animation: bounce 1s infinite 3s;
  a {
    display: inline-block;
    width: 1.6em;
    height: 5em;
    transform: rotate(90deg);
    background-image: url(../img/slider_arrow_white.svg);
    background-repeat: no-repeat;
  }
}

@keyframes bounce {
  0%, 100%, 20%, 50%, 80% {transform: translate(0px,0px);};
  40% {transform: translate(0px,15px);};
  60% {transform: translate(0px,5px);};
}


// Testimonial
#testimonial-slider {
  min-height: 33.333vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h2.testimonial-title {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 2.7em;
  color: $color-white;
  z-index: 100;
  top: 1em;
}
.testimonial {
  width: 100%;
  padding-top: 9em;
  padding-bottom: 8em;
  text-align: center;
  color: $color-white;
  max-width: 600px;
  margin: 0 auto;
  blockquote {
    font-size: 2em;
    margin-bottom: 1em;
    font-style: italic;
  }
  p {
    font-size: $font-size-standard;
    margin-bottom: 20px;
  }
}


// Backgrounds general

.background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.background-brown1 .box-color {
  fill: $color-brown1;
}
.background-brown2 .box-color {
  fill: $color-brown2;
}
.background-brown3 .box-color {
  fill: $color-brown3;
}
.background-red1 .box-color {
  fill: $color-red1;
}
.background-yellow1 .box-color {
  fill: $color-yellow1;
}
.background-orange1 .box-color {
  fill: $color-orange1;
}
.background-green1 .box-color {
  fill: $color-green1;
}
.background-green2 .box-color {
  fill: $color-green2;
}


// CONTENT

// provisional
#for h2 {
  font-size: 2.7em;
}

.content {


  h1,h2,h3,h4,h5,h6 {
    margin: 0;
    padding: 0;
    line-height: $font-line-height-small;
    margin-bottom: 0.5em;
  }
  
  h1 {
    font-size: 4em;
  }

  h2 {
    font-size: 4em;
  }


  
  h3 {
    font-size: 2.7em;
  }
  
  h4 {
    font-size: 2.2em;
  }

  p,
  ul,
  ol,
  table,
  em {
    margin: 0;
    padding: 0;
    font-size: $font-size-standard;
    line-height: $font-line-height-standard;
  }
  
  p {
    margin-bottom: 0.5em;
  }
  
  p.lead {
    font-size: 1.3em;
    line-height: 1.5em;
    margin-bottom: 1.5em;
    em {
        font-style: normal;
    }
  }
  
  p.leadsmall {
    font-size: 1.15em;
    em {
      font-style: normal;
    }
  }
  
  .small {
    font-size: 0.8em;
  }
  

  
  b,strong {
    font-weight: 700;
  }
  
  figcaption {
    margin-top: 0.5em;
    margin-bottom: 1em;
    font-size: $font-size-small;
    line-height: 1.2em;
  }
  
  a {
    border-bottom: 1px solid;
    &:hover {
      color: $color-black;
      border-bottom-color: $color-black;
    }
  }
  
  a.button {
    display: inline-block;
    font-size: $font-size-standard;
    font-weight: 700;
    text-decoration: none;
    border-bottom: none;
    cursor: pointer;
    padding-left: 1.8em;
    margin-bottom: 0.2em;
    &:before {
      content: "";
      position: absolute;
      margin-left: -1.8em;
      background: url(../img/icon_arrow_brown2_passiv.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 1.4em;
      height: 1.4em;
    }
    &:hover {
      color: $color-brown2;
      &:before {
        background: url(../img/icon_arrow_brown2_activ.svg);
        background-size: contain;
      }
    }
  }
  
  a.button-download {
    &:before {
      transform: rotate(90deg); 
    }
  }

  a.button-open-close {
    margin-top: 0.5em;
    &:before {
      background: url(../img/icon_plus_brown2_passiv.svg);
      background-size: contain;
    }
    &:hover {
      &:before {
        background: url(../img/icon_plus_brown2_activ.svg);
        background-size: contain;
      }
    }
  }
  
  a.button-close {
    &:before {
      background: url(../img/icon_minus_brown2_passiv.svg);
      background-size: contain;
    }
    &:hover {
      &:before {
        background: url(../img/icon_minus_brown2_activ.svg);
        background-size: contain;
      }
    }
  }
  
  ul {
    margin: 0;
    padding-left: 1.2em;
    list-style: none;
    li {
      margin-bottom: 0.5em;
      &:before {
        content: "–";
        position: relative;
        margin-left: -1.2em;
        padding-right: 0.5em;
      }
    }
  }
  
  ul.horseshoe {
    padding-left: 2em;
    font-size: 1.8em;
    li {
      &:before {
        content: "";
        position: absolute;
        width: 1em;
        height: 1.7em;
        margin-left: -2em;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(../img/icon_horsshoe_brown2.svg);
      }
    }
  }
  
  ol {
    margin: 0;
    padding-left: 1.2em;
    li {
      margin-bottom: 0.5em;
      &:before {
        position: relative;
        margin-left: -1.2em;
        padding-right: 0.5em;
      }
    }
  }
  
  table {
    text-align: left;
    width: 100%;
    border-collapse: collapse;
  }
  th,td {
    padding: 0.2em;
    vertical-align: top;
    border-bottom: solid 1px $color-brown2;
  }
  th {
    padding-top: 0em;
    border-bottom: solid 2px $color-brown2;
  }
  tfoot {
    font-size: 0.9em;
    font-weight: 700;
  }
  
  // Offers Overview

  $size-grid-overview-offer-cutter: 1em;
  
  /* .grid-overview-offer {
    overflow: hidden;
    margin-left: -$size-grid-overview-offer-cutter;
    margin-right: -$size-grid-overview-offer-cutter;
  } */

  .grid-overview-offer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $size-grid-overview-offer-cutter * 2;
    justify-items: stretch;
    align-items: stretch;

    .overview-offer-box {
      /* height: 100%; */

      .text {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
        height: 100%;
      }
    }
  }
  
  /* .overview-offer {
    overflow: hidden;
    padding-left: $size-grid-overview-offer-cutter;
    padding-right: $size-grid-overview-offer-cutter;
    margin-bottom: $size-grid-overview-offer-cutter*2;
    float: left;
    width: 33.333%;
  } */
 
  .overview-offer-box {
    padding: 1.8em;
    color: $color-white;
    position: relative;
    .text {
      position: relative;
      h3 {
        font-size: 2.2em;
      }
    }
    .title {
      font-size: 1.8em;
      border-top: solid 3px;
      border-top-style: dotted;
      padding-top: 0.2em;
      margin-top: 0.8em;
    }
    li {
      margin-bottom: 0;
    }
    .button {
      color: $color-white;
      &:before {
        background-image: url(../img/icon_arrow_white_passiv.svg);
      }
      &:hover {
        color: $color-white;
        &:before {
          background-image: url(../img/icon_arrow_white_activ.svg);
        }
      }
    }
  }
  
  
  //Details Offers
  .offer-grid {
    padding-top: 3em;
    padding-bottom: 1em;
    border-bottom: 3px solid;
    border-bottom-style: dotted;
    .heading {
      position: relative;
      display: inline-block;
      margin-left: -1em;
      padding: 0.5em 1em 0.8em 1em;
      margin-bottom: 1.2em;
    }
    h2,
    h3 {
      position: relative;
      color: $color-white;
      margin-bottom: 0;
    }
    .button-close {
      .status-on {
        display: inline;
      }
      .status-off {
        display: none;
      }
    }
    .button-open {
      .status-on {
        display: none;
      }
      .status-off {
        display: inline;
      }
    }
  }
  
  .info {
    .container {
      margin-right: -250px;
      float: left;
      width: 100%;
    }
    .content {
      margin-right: 300px;
    }
    .sidebar {
      width: 250px;
      float: left;
    }
  }
  
  .info-bottom {
    overflow: hidden;
    margin-top: 1em;
    display: none;
  }
  
  //Team
  .team {
    margin-top: 1em;
  }
  


}


// CONTACT FORM

.contactform {
  width: 100%;
  label {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
  input, textarea {
    width: 100%;
    margin-bottom: 1em;
    background: transparent;
    padding: .75em 1em;
    border: 1px solid $color-orange1;
    border-radius: 3px;
    color: $color-white;
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;

    &::placeholder {
      color: $color-brown1;
    }
    &:focus {
      border-color: $color-yellow1;
      outline: none;
    }
    &.invalid {
      border-color: $color-error;
    }
    &#hnp {
      display: none;
    }

  }
  #submit {
    background-color: $color-red1;
    color: $color-white;
    border: none;
    border-radius: 3px;
    padding: .75em 1em;
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;

    &:hover {
      background-color: lighten($color-red1, 5%);
    }
    &:focus {
      outline: none;
      /* border: 1px solid $color-yellow1; */
    }
  }
  .result {
    margin-bottom: 1em;
    font-size: 1em;
    &.error { color: $color-error; }
    &.warning { color: $color-warning; }
    &.success { color: $color-success; }
    &.info { color: $color-info; }
  }
}

// FOOTER

#footer {
  text-align: center;
  background-color: $color-brown2;
  color: $color-white;
  font-size: $font-size-standard;
  p {
    font-style: normal;
    margin-bottom: 0.5em;
  }
  a {
    color: $color-white;
    &:hover {
      color: $color-white;
      border-bottom: 1px solid $color-white;
    }
  }
  
  .contact-block {
    padding-top: 2em;
    padding-bottom: 2em;
    position: relative;
    text-align: left;
    h3 {
      font-size: 1.2em;
      margin-bottom: 2em;
    }
    .content-width {
      max-width: 1100px;
    }
    .contact-infos {
      position: relative;
      display: inline-block;
      width: 100%;
      padding: .8em 1em .5em 1em;
      margin-bottom: 1.2em;

      .text {
        position: relative;
        color: #fff;

        h4 {
          margin-bottom: 1em;
        }

        a.button-xing {
          display: inline-block;
          //font-size: $font-size-standard;
          font-weight: 700;
          text-decoration: none;
          border-bottom: none;
          cursor: pointer;
          padding-left: 1.8em;
          margin-bottom: .5em;
          line-height: 1.4;

          &:before {
            content: '';
            background: url(../img/icon_social-xing_white_passiv.svg);
            background-size: contain;
            position: absolute;
            margin-left: -1.8em;
            background-repeat: no-repeat;
            width: 1.4em;
            height: 1.4em;
          }

          &:hover {
            &:before {
              background: url(../img/icon_social-xing_white_activ.svg);
              background-size: contain;
            }
          }
        }
      }
    }
  }
  
  .contact {
    width: 50%;
  }
  
  .content-width {
    max-width: 600px;
  }
  
  .contact-left {
    float: left;
  }
  
  .contact-right {
    float: right;
  }
  
  .contact-social {
    width: 26%;
    margin-left: -13%;
    left: 50%;
    position: absolute;
    top: 3em;
  }
  
  .social-instagram {
    cursor: pointer;
    display: inline-block;
    padding-top: 2.2em;
    background-image: url(../img/icon_social-instagram_white_passiv.svg);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 2em;
    &:hover {
      background-image: url(../img/icon_social-instagram_white_activ.svg);
    }
  }
  
  .legal-block {
    font-weight: 300;
    background-color: $color-brown3;
    padding-top: 1em;
    padding-bottom: 1.5em;
  }
  
  .footer-navigation,
  .footer-copyright {
    display: inline-block;
  }
  
  .footer-navigation {
    margin-right: 1em;
    margin-bottom: .5em;
  }

}





// Impressum Datenschutz

body.info-page {
  padding-top: $size-header-height-scrolled;
  background-color: $color-white;
  .content-width {
    max-width: $size-content-width-info-page;
  }
}

#header-info {
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100%;
  height: $size-header-height-scrolled;
  background: $color-brown2;
  color: $color-white;
}

#info-page-content{
  position: relative;
  height: $size-header-height-scrolled;
}

#info-page-title {
  position: absolute;
  display: inline-block;
  left: 0;
  bottom: 12px;
  text-align: left;
  font-size: 1.8em;
  margin: 0;
  padding-right: 50px;
}

#info-page-close {
  position: absolute;
  display: inline-block;
  right: 0;
  bottom: 1em;
  width: 3em;
  height: 3em;
  background-size: contain;
  background-image: url(../img/icon_close_white_passiv.svg);
  &:hover {
    background-image: url(../img/icon_close_white_activ.svg);
  }
}



/* Responsive (Breakpoint) -------------------------------- */
@media only screen 
and (max-width : 850px) { /* START */

  
  .content {
    /* .overview-offer {
      width: 50%;
    } */
    .grid-overview-offer {
      grid-template-columns: 1fr 1fr;
    }

    .info {
      h3 {
        margin-top: 1em;
      }
      .container {
        margin-right: 0;
        float: none;
        width: 100%;
      }
      .content {
        margin-right: 0;
      }
      .sidebar {
        width: 100%;
        float: none;
      }
    }
    
  }

} /* END */



/* Responsive (Breakpoint) -------------------------------- */
@media only screen 
and (max-width : 740px) { /* START */

  .spacer-height-small {
    height: $spacer-height-small/100*75;
  }
  .spacer-height-medium {
    height: $spacer-height-medium/100*75;
  }
  .spacer-height-big {
    height: $spacer-height-big/100*75;
  }

  .grid-responsive {
    .col-33-3 {
      width: 100%
    }
    .col-33-2,
    .col-66-2 {
      width: 50%
    }
  }
  
  .grid {
    margin-left: -$size-grid-cutter-small;
    margin-right: -$size-grid-cutter-small;
  }
  .col {
    padding-left: $size-grid-cutter-small;
    padding-right: $size-grid-cutter-small;
  }
  
  .teaser {
    left: $size-header-margin;
    padding: 1.5em;
    .title {
      font-size: 5em;
    }
    .description {
      font-size: 2.2em;
    }
  }

  #footer {
    .contact-block {
      h2 {
        margin-bottom: 1em;
      }
    } 
    .contact {
      width: 100%;
    }
    
    .contact-left {
      float: none;
    }
    
    .contact-right {
      float: none;
    }
    
    .contact-social {
      width: 100%;
      margin-left: 0;
      left: 0;
      position: relative;
      top: 1em;
    }
  }
  

} /* END */


/* Responsive (Breakpoint) -------------------------------- */
@media only screen 
and (max-height : 550px) { /* START */

  .teaser {
    margin-bottom: 100px;
  }

} /* END */



/* Responsive (Breakpoint) -------------------------------- */
@media only screen 
and (max-width : 625px) { /* START */

  .content {
    .grid-overview-offer {
      grid-template-columns: 1fr;
    }
  }

} /* END */


/* Responsive (Breakpoint) -------------------------------- */
@media only screen 
and (max-width : 580px) { /* START */


  .grid-responsive {
    .col-33-2,
    .col-66-2,
    .col-50-2 {
      width: 100%
    }
  }
  
  .content {
    h2 {
      font-size: 3.2em;
    }
  }
  
  .swiper-slide {
    .teaser {
      .description {
        display: none;
      }
      .cta {
        text-align: left;
      }
    }
  }

  .contactform {
    margin-bottom: 2em;
  }
  
} /* END */


/* Responsive (Breakpoint) -------------------------------- */
@media only screen 
and (max-width : 470px) { /* START */


  .header-content {
    padding-left: $size-header-margin-small;
    padding-right: $size-header-margin-small;
  }
  
  .content-width {
    max-width: $size-content-width + (2 * $size-content-margin-small);
    padding-left: $size-content-margin-small;
    padding-right: $size-content-margin-small;
  }
  
  .pagescrolled {
    .header-content {
      margin-left: $size-content-margin-small;
      margin-right: $size-content-margin-small;
    }
    .header-content-width {
      padding-left: -$size-content-margin-small;
      padding-right: -$size-content-margin-small;
    }
  }
  
  #logo {
    top: -20px;
    width: 150px;
    height: 87px;
  }

} /* END */


/* Responsive (Breakpoint) -------------------------------- */
@media only screen 
and (max-width : 370px) { /* START */

  body {
    font-size: 9px;
  }
  
  .teaser {
    .title {
      font-size: 4em;
    }
    .description {
      font-size: 1.5em;
    }
    .cta {
      text-align: left;
    }
  }
  
} /* END */
